<template>
	<div :id="`block-${$vnode.key}`" class="c-block-list-cta">
		<BaseButton
			v-if="link && link.url"
			class="
				ml-auto
				mt-16
				>=768:mt-0
				justify-between
				flex-shrink-0 flex-grow-0
				gap-x-4xs/h
			"
			:class="[
				'bg-theme hover:bg-theme-90 text-white border border-current',
				link.type === 'media' ? 'matomo_download' : 'matomo_link',
			]"
			:to="link.url"
			:target="
				link.type && link.type === 'content' && link.target !== '_blank'
					? ''
					: '_blank'
			"
			:download="link.type === 'media' ? link.name : null"
		>
			<!-- To avoid the button from changing size -->
			<span v-text="link.name"></span>

			<SvgIconLock v-if="restricted" :class="['w-16 h-16']" />
			<SvgIconDownload
				v-else-if="link.type && link.type === 'media'"
				:class="['w-16 h-16']"
			/>
			<SvgIconExternal
				v-else-if="
					(link.type && link.type === 'external') ||
					link.target === '_blank'
				"
				:class="['w-16 h-16']"
			/>
			<span
				v-if="restricted || link.type === 'media'"
				class="opacity-70 text-body-xs whitespace-nowrap flex-shrink-0"
				v-text="
					restricted
						? 'Adgangsbeskyttet'
						: `${link.extension.toUpperCase()} | ${link.size}`
				"
			></span>
		</BaseButton>
	</div>
</template>

<script>
import SvgIconLock from '~/assets/svgs/icon-lock.svg?inline';
import SvgIconDownload from '~/assets/svgs/icon-download.svg?inline';
import SvgIconExternal from '~/assets/svgs/icon-external.svg?inline';

export default {
	name: 'BlockListCta',
	components: { SvgIconLock, SvgIconDownload, SvgIconExternal },
	inheritAttrs: false,

	props: {
		link: {
			type: Object,
			default: () => ({}),
		},
		restricted: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
