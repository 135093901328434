var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-block-list-cta",attrs:{"id":("block-" + (_vm.$vnode.key))}},[(_vm.link && _vm.link.url)?_c('BaseButton',{staticClass:"\n\t\t\tml-auto\n\t\t\tmt-16\n\t\t\t>=768:mt-0\n\t\t\tjustify-between\n\t\t\tflex-shrink-0 flex-grow-0\n\t\t\tgap-x-4xs/h\n\t\t",class:[
			'bg-theme hover:bg-theme-90 text-white border border-current',
			_vm.link.type === 'media' ? 'matomo_download' : 'matomo_link' ],attrs:{"to":_vm.link.url,"target":_vm.link.type && _vm.link.type === 'content' && _vm.link.target !== '_blank'
				? ''
				: '_blank',"download":_vm.link.type === 'media' ? _vm.link.name : null}},[_c('span',{domProps:{"textContent":_vm._s(_vm.link.name)}}),_vm._v(" "),(_vm.restricted)?_c('SvgIconLock',{class:['w-16 h-16']}):(_vm.link.type && _vm.link.type === 'media')?_c('SvgIconDownload',{class:['w-16 h-16']}):(
				(_vm.link.type && _vm.link.type === 'external') ||
				_vm.link.target === '_blank'
			)?_c('SvgIconExternal',{class:['w-16 h-16']}):_vm._e(),_vm._v(" "),(_vm.restricted || _vm.link.type === 'media')?_c('span',{staticClass:"opacity-70 text-body-xs whitespace-nowrap flex-shrink-0",domProps:{"textContent":_vm._s(
				_vm.restricted
					? 'Adgangsbeskyttet'
					: ((_vm.link.extension.toUpperCase()) + " | " + (_vm.link.size))
			)}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }